import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import HallInfo from "../HallInfo";
import ImageBanner from "../ImageBanner";
import NavBar from "../NavBar";
import { Col, Dropdown, Row } from "react-bootstrap";
import ReviewPagination from "../ReviewPagination";

export type Review = {
  id: string;
  hallId: string;
  userId: string;
  rating: number;
  title?: string;
  reviewText: string;
  author: string;
  createdDate: string;
  lastEdited: string;
};

type Hall = {
  id: string;
  name: string;
  bio: string;
  population: number;
  campusDistance: number;
  cost: number;
  latitude: number;
  longitude: number;
};

type HallPageProps = {
  view: "reviews" | "learn-more";
};

const HallPage = ({ view }: HallPageProps) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

  const [queryString, setQueryString] = useState<string>(
    `${API_BASE_URL}/reviews/${id}`
  );
  const [showReviews, setShowReviews] = useState<boolean>(true);
  const [averageRating, setAverageRating] = useState<number | undefined>(
    undefined
  );
  const [reviews, setReviews] = useState<Review[]>([]);

  const [hallData, error] = useFetch<Hall>(`${API_BASE_URL}/hall/GetHall/${id}`);
  const [reviewsData] = useFetch<Review[]>(queryString);
  
  useEffect(() => {
    setQueryString(`${API_BASE_URL}/reviews/${id}`);
  }, [API_BASE_URL, id]);

  useEffect(() => {
    if (error)
    {
      console.log(error);
      navigate("/");
    }
  }, [navigate, error])

  // If the user selects a sort by preference, then sort accordingly
  const sortByHandler = (sortBy: string | undefined) => {
    switch (sortBy) {
      case "LATEST":
        setQueryString(
          `${API_BASE_URL}/reviews/${id}?SortBy=CreatedDateRecent`
        );
        break;
      case "HIGHEST_RATED":
        setQueryString(`${API_BASE_URL}/reviews/${id}?SortBy=RatingHighest`);
        break;
      case "LOWEST_RATED":
        setQueryString(`${API_BASE_URL}/reviews/${id}?SortBy=RatingLowest`);
        break;
      default:
        setQueryString(
          `${API_BASE_URL}/reviews/${id}?SortBy=CreatedDateRecent`
        );
        break;
    }
  };
 
  const switchViews = () => {
    setShowReviews((currentVal) => !currentVal);
  };

  console.log(hallData);

  useEffect(() => {
    if (!reviewsData) {
      return;
    }
    let avg = undefined;
    if (reviewsData.length > 0) {
      avg =
        reviewsData.reduce(
          (accumulator: number, current: Review) =>
            accumulator + current.rating,
          0
        ) / reviewsData.length;
    }
    if (avg) avg = Math.round(avg * 100) / 100;

    setAverageRating(avg);
    setReviews(reviewsData);
  }, [reviewsData]);

  const sortOptions = (
    <Row>
      <Col xs={12}>
        {reviews && reviews?.length > 0 && (
          <>
            <Dropdown
              data-bs-theme="dark"
              className="sort-reviews-dropdown mb-3"
            >
              <Dropdown.Toggle>Sort reviews</Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    sortByHandler("LATEST");
                  }}
                >
                  Latest
                </Dropdown.Item>
                <Dropdown.Item onClick={() => sortByHandler("HIGHEST_RATED")}>
                  Highest rated
                </Dropdown.Item>
                <Dropdown.Item onClick={() => sortByHandler("LOWEST_RATED")}>
                  Lowest rated
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}
      </Col>
    </Row>
  );

  return (
    <>
      <NavBar />

      {hallData && (
        <>
          <ImageBanner
            hallName={hallData.name}
            reviewAvg={averageRating}
            numReviews={reviewsData?.length}
            showReviews={showReviews}
            showReviewsCallback={switchViews}
          />

          {showReviews ? (
            <div className="hall-reviews-container p-4 mb-5">
              {sortOptions}
              <ReviewPagination reviews={reviews} pageSize={8} pageLimit={10} />
            </div>
          ) : (
            <HallInfo
              title={hallData.name}
              bio={hallData.bio}
              population={hallData.population}
              cost={hallData.cost}
              distance={hallData.campusDistance}
              latitude={hallData.latitude}
              longitude={hallData.longitude}
            />
          )}
        </>
      )}
    </>
  );
};

export default HallPage;
