import { useAuth0 } from "@auth0/auth0-react";
import { Container, Nav, NavDropdown } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import LoginButton from "./buttons/LoginButton";
import LogoutButton from "./buttons/LogoutButton";
import SignupButton from "./buttons/SignupButton";
import { useEffect, useState } from "react";
import { UserData } from "./routes/Profile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapLocationDot } from "@fortawesome/free-solid-svg-icons";

export type Hall = {
  id: string;
  name: string;
  bio: string;
  population: number;
  campusDistance: number;
  cost: number;
  imgSrc: string;
  latitude: number;
  longitude: number;
  averageRating: number;
};

type NavBarProps = {
  showMap?: boolean;
  setShowMap?: React.Dispatch<React.SetStateAction<boolean>>;
};

const NavBar = ({ showMap, setShowMap }: NavBarProps) => {
  const navigate = useNavigate();
  const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;
  const { isAuthenticated, user } = useAuth0();

  const [data] = useFetch<Hall[]>(`${API_BASE_URL}/hall/GetHalls`);
  const [userData] = useFetch<UserData>(`${API_BASE_URL}/user/${user?.sub}`);
  const [isAllowedToLeaveReview, setIsAllowedToLeaveReview] =
    useState<boolean>(false);

  useEffect(() => {
    if (!userData) {
      setIsAllowedToLeaveReview(false);
      return;
    }

    var env = process.env.NODE_ENV;
    var reviewExists =
      userData.review !== null && userData.review !== undefined;

    if (env === "development" || !reviewExists) {
      setIsAllowedToLeaveReview(true);
    } else {
      setIsAllowedToLeaveReview(false);
    }
  }, [userData]);

  return (
    <Navbar
      bg="dark"
      data-bs-theme="dark"
      className="bg-body-tertiary app-navigation"
    >
      <Container fluid={true}>
        <Navbar.Brand className="hover-cursor" onClick={() => navigate("/")}>
          Rate My Hall
        </Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavDropdown title="Halls" id="basic-nav-dropdown">
              {data &&
                data.map((hall: Hall) => (
                  <NavDropdown.Item
                    key={hall.id}
                    onClick={() => navigate(`/hall/${hall.id}`)}
                  >
                    {hall.name}
                  </NavDropdown.Item>
                ))}
            </NavDropdown>
            {isAllowedToLeaveReview && (
              <Nav.Link onClick={() => navigate("/leave-review")}>
                Leave Review
              </Nav.Link>
            )}
          </Nav>
          <Nav>
            {!showMap && setShowMap != null && (
              <FontAwesomeIcon
                className="nav-map-icon"
                icon={faMapLocationDot}
                style={{
                  position: "relative",
                  right: "12px",
                  top: "7px",
                  fontSize: "1.5rem",
                  cursor: "pointer",
                }}
                onClick={() => setShowMap(true)}
              />
            )}
            {isAuthenticated && (
              <>
                <Nav.Link onClick={() => navigate("/profile")}>
                  {user?.name}
                </Nav.Link>
                <LogoutButton />
              </>
            )}
            {!isAuthenticated && (
              <>
                <SignupButton />
                <Navbar.Text>|</Navbar.Text>
                <LoginButton />
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
