import { useEffect } from "react";
import { useState } from "react";
import { Pagination } from "react-bootstrap";
import ReviewGrid from "./ReviewGrid";

type Review = {
  id: string;
  hallId: string;
  userId: string;
  rating: number;
  title?: string;
  reviewText: string;
  createdDate: string;
  author: string;
  lastEdited: string;
};

type ReviewPaginationProps = {
  reviews: Review[];
  pageSize: number;
  pageLimit: number; // The number of pages allowed before using ellipsis
};

const ReviewPagination = ({ reviews, pageSize, pageLimit }: ReviewPaginationProps) => {
  const pages = Math.ceil(reviews?.length / pageSize);
  const [page, setPage] = useState<number>(1);
  const [reviewsInPage, setReviewsInPage] = useState<Review[]>(
    reviews.slice(0, page * pageSize)
  );

  useEffect(() => {
    setPage(1);
  }, [reviews]);

  useEffect(() => {
    setReviewsInPage(reviews.slice((page - 1) * pageSize, page * pageSize));
  }, [page, reviews, pageSize]);

  const regularPaginationCounter = Array.from(Array(pages).keys()).map((n) => (
    <Pagination.Item
      key={n}
      disabled={page === n + 1}
      onClick={() => setPage(n + 1)}
    >
      {n + 1}
    </Pagination.Item>
  ));

  const buildUpPageMatrix = () => {
    const pageMatrix = [];
    const setsOfPages = Math.ceil(pages / pageLimit);

    for (let i = 0; i < setsOfPages; i++) {
      var row = [];
      for (let j = 0; j < pageLimit; j++) {
        if (i * pageLimit + j >= pages) {
          break;
        }
        row[j] = i * pageLimit + j + 1;
      }
      pageMatrix[i] = row;
    }

    return pageMatrix;
  };

  // TODO: big refactor needed here - put testing in place first
  const longPaginationCounter = () => {
    // building up an array of arrays that contain page sets
    const pageMatrix = buildUpPageMatrix();

    const paginationElements = [];
    const currentSet = Math.ceil(page / pageLimit);
    const pagesInSet = pageMatrix[currentSet - 1];

    const isCurrentSetFirstSet = currentSet === 1;
    const isCurrentSetLastSet = currentSet === pageMatrix.length;
    const isCurrentSetMiddleSet = !isCurrentSetFirstSet && !isCurrentSetLastSet;

    if (isCurrentSetLastSet || isCurrentSetMiddleSet) {
      paginationElements.push(
        <Pagination.Ellipsis 
          aria-label="ellipsis left"
          onClick={() => setPage(pagesInSet[0] - 1)} 
        />
      );
    }

    paginationElements.push(
      ...pagesInSet.map((pageNum) => (
        <Pagination.Item
          key={pageNum}
          disabled={page === pageNum}
          onClick={() => setPage(pageNum)}
        >
          {pageNum}
        </Pagination.Item>
      ))
    );

    if (isCurrentSetFirstSet || isCurrentSetMiddleSet) {
      paginationElements.push(
        <Pagination.Ellipsis
          aria-label="ellipsis right"
          onClick={() => setPage(pagesInSet[pagesInSet.length - 1] + 1)}
        />
      );
    }

    return paginationElements;
  };

  return reviews.length > pageSize ? (
    <>
      <ReviewGrid reviews={reviewsInPage} />
      <Pagination className="review-pagination mt-5" data-testid="pagination-element">
        <Pagination.Prev
          disabled={page === 1}
          aria-label="previous"
          onClick={() => setPage((currentPage) => currentPage - 1)}
        />

        {pages <= pageLimit
          ? regularPaginationCounter
          : longPaginationCounter()}

        <Pagination.Next
          className="next-page"
          aria-label="next"
          disabled={page === pages}
          onClick={() => setPage((currentPage) => currentPage + 1)}
        />
      </Pagination>
    </>
  ) : <ReviewGrid reviews={reviewsInPage} />;
};

export default ReviewPagination;
