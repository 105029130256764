import React from 'react';

type ImageBannerProps = {
    imgSrc?: string;
    hallName: string;
    reviewAvg: number | undefined;
    numReviews: number | undefined;
    showReviews: boolean;
    showReviewsCallback: () => void;
}

const ImageBanner = ({imgSrc, hallName, reviewAvg, numReviews, showReviews, showReviewsCallback}: ImageBannerProps) => {
    return (
        <>
            <div className="image-banner">
                <img className="hall-img" alt="Otago University Clocktower" src={imgSrc || "/assets/images/clocktower.jpeg"} />
                <div className="image-overlay"></div>
                <div className="inner-banner">
                    <h2 className="mr-3">{hallName}</h2>
                    <button className="ml-5 btn btn-info" onClick={() => showReviewsCallback()}>{showReviews ? "Read More" : "Read Reviews"}</button>
                </div>
                
                <div className="ribbon">
                    <ul className="facts-list">
                    {reviewAvg ? (
                        <li>{reviewAvg}/10 <small><span className="text-muted">(from {numReviews} reviews)</span></small></li>
                    ) : (
                        <li>No reviews yet</li>
                    )}
                    </ul>
                </div>
            </div>
        </>
    )

};

export default ImageBanner;