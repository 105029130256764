import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useEffect } from "react";
import { Card } from "react-bootstrap";
import { ReviewCardModalData } from "./ReviewCardModal";
import { formatDateStr } from "../helpers/DateHelper";

type ReviewProps = {
  id: string;
  title?: string;
  review: string;
  rating: number;
  date: string;
  author: string;
  maxAuthorLength: number;
  onClickHandler: (review: ReviewCardModalData) => void;
};

const ReviewCard = ({
  id,
  title,
  review,
  rating,
  date,
  author,
  maxAuthorLength,
  onClickHandler,
}: ReviewProps) => {
  const [reviewText, setReviewText] = useState<string>(review);
  const reviewLengthCutoff = 170; // What is a reasonable cutoff?

  useEffect(() => {
    if (review?.length > reviewLengthCutoff) {
      setReviewText(review.substring(0, reviewLengthCutoff).trimEnd() + "...");
    }
  }, [review]);

  const reviewCardModalData = {
    id: id,
    title: title,
    reviewText: review,
    rating: rating,
    createdDate: date,
    author: author,
  };

  const truncateAuthor = (author: string) => {
    const AuthorLimit = 12;
    if (author.length > AuthorLimit)
    {
      return author.substring(0,AuthorLimit - 3) + "...";
    }
    return author;
  }

  return (
    <Card onClick={() => onClickHandler(reviewCardModalData)}>
      <Card.Header className="review-header mt-2">
        {title ? (
          <>
            <Card.Title>
              <span className="review-title">{title}</span>
            </Card.Title>
            <div style={{ fontSize: "0.9rem" }}>
              <span style={{ fontWeight: 200, fontSize: "1.3rem" }}>{rating}</span>
              <span>/10</span>
            </div>
          </>
        ) : (
          <>
            <div style={{ fontSize: "0.9rem" }}>
              <span style={{ fontWeight: 200, fontSize: "1.3rem" }}>{rating}</span>
              <span>/10</span>
            </div>
          </>
        )}
      </Card.Header>
      <Card.Body className="mt-0 pt-0">
        <Card.Text>{reviewText}</Card.Text>
      </Card.Body>
      <Card.Footer style={{ fontSize: "0.8rem" }}>
        <small className="float-start text-muted review-date">{formatDateStr(date)}</small>
        <small className="float-end text-muted">
          {truncateAuthor(author)}
          <FontAwesomeIcon
            style={{ marginLeft: "8px" }}
            icon={faUser}
            inverse
          />
        </small>
      </Card.Footer>
    </Card>
  );
};

export default ReviewCard;
